:root {
    --text-color: #E0E0E0; /* softer color for text */
    --background-color: #282828; /* softer color for background */
    --lighter-background-color: #383838; /* slightly lighter color for hover effects */
    --primary-color: #27953b;; /* primary color */
    --secondary-color: #3700B3; /* secondary color */
    --accent-color: #5ea13c; /* accent color */
}

.container {
    border: 1px solid var(--lighter-background-color);
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    max-height: 500px;
    margin-top: 60px;
    padding: 0;
    box-shadow: 0px 0px 10px rgb(0, 0, 0);
    justify-content: space-around; /* evenly distribute items along the line */
    flex-wrap: wrap;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* animate border and shadow changes */
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    padding: 0px;
}

.container:hover {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
}


.table {
    border-collapse: separate;
    top: 0px;
    background-color: gray;
    min-width: 100%;
}

.table th,
.table td {
    border: 1px solid gray;
    padding: 8px;
    margin: 0;
    word-wrap: break-word;
    max-width: 200px;
    overflow: auto;
    font-size: 12px;
    background-color: #282828;
    transition: transform 0.15s;
    user-select: none;
}

.table td:hover {
    z-index: 1000;
    background-color: #d4af37;
    transform: scale(1.25);
    border-color: #d4af37;
    color: black;
}

.table th {
    border-width: 1px;
    padding-top: 5px;
    text-align: left;
    background-color: #224a20;
    color: white;
    position: sticky;
    top: 0;
    opacity: 100;
}

.table th:hover {
    background-color: #d4af37;
    color: black;
    border-color: #d4af37;
}

.filter { 
    display: inline-block;
    padding: 2px 7px;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    color: grey;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    transition-duration: 0.4s;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.filter:hover {
    background-color: white;
    color: black;
}